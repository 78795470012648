import { Figure } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function CustomerTemplate({details}){

  const width = 200; // Set your desired width
  const height = (width); // Calculate height based on 16:9 aspect ratio

  const navigator = useNavigate();
  
  const handleClick = (customer) => {
    navigator('/customerFullDetails', {state : {customer}});
  }

    return(
        <>
            <Figure key={details.customerId} className="figure-wrapper">
                <Figure.Image  thumbnail
                    style={{ width: `${width}px`, height: `${height}px`, margin: '5px' }}
                    src={details.photo}
                    alt={details.name}
                    onClick={() => handleClick(details)}
                />
                <div className="divFlexSpan">
                <Figure.Caption> <strong>{details.customerId}</strong></Figure.Caption>
                </div>
            </Figure>
        </>
    );
}

export default CustomerTemplate;