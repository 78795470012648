import React, { useState } from 'react';
import { Container, Navbar, Button, Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useAuth } from './Security/AuthContext';
import { IoHome } from "react-icons/io5";
import { GrLogin, GrLogout } from "react-icons/gr";
import { RiContactsBookLine } from "react-icons/ri";
import { TfiViewList } from "react-icons/tfi";
import { FaSitemap } from "react-icons/fa";
import { FaUsersBetweenLines } from "react-icons/fa6";
import { MdPermDeviceInformation } from "react-icons/md";
import smsLogo from './SMS_logo_Updated.png';
import './navBar.css';


function ApplicationNavBar() {
  const [expanded, setExpanded] = useState(false);
  const { isAuthenticated, logout } = useAuth();

  const handleNavCollapse = () => {
    setExpanded(false);
  };

  return (
    <div>
          <Navbar collapseOnSelect fixed="top" expand="md" className="navbar-color" expanded={expanded}>
  <Container>
    <Navbar.Brand href='' className="logo-text-color">
      <img
        src={smsLogo}
        height="50rem"
        className="d-inline-block align-top"
        alt="SMS Logo"
      />
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(!expanded)} />
    <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="me-auto" onClick={handleNavCollapse}>
        <Button variant="outline-primary me-1" className="btn" as={NavLink} to="/home" exact>
          <IoHome /> Home
        </Button>

        {isAuthenticated ? 
        <>
          <Button variant="outline-success me-1" className="btn" as={NavLink} to="/adminServices">
            <FaUsersBetweenLines /> AdminService
          </Button>              
          
          <Button variant="outline-warning me-1" className="btn" as={NavLink} to="/activeOrdersList">
            <TfiViewList /> OrderList
          </Button>
        </> : ''}
          <Button variant="outline-info me-1" className="btn" as={NavLink} to="/contactUs">
          <RiContactsBookLine /> ContactUs
        </Button>
        <Button variant="outline-info me-1" className="btn" as={NavLink} to="/aboutUs">
          <MdPermDeviceInformation /> AboutUs
        </Button>
      </Nav>
      <Nav className="ms-auto" onClick={handleNavCollapse}>
        {isAuthenticated ? 
          <Button variant="dark me-1" className="btn" as={NavLink} to="/login" onClick={logout}>
            <GrLogout /> LogOut
          </Button>
          : <Button variant="dark me-1" className="btn" as={NavLink} to="/login">
            <GrLogin /> AdminLogIn
          </Button> }
      </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>

    </div>
  );
}

export default ApplicationNavBar;
