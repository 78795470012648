import React from 'react';
import { Container } from 'react-bootstrap';

function Home() {
  return (
    
      <Container fluid>
        {/* <Row> 
          <Col style={{padding:'10px 0px', marginTop:'4rem'}}>
            <HomePageCarosel />
          </Col>
        </Row>
        <Row>
          <Col style={{padding:'10px 0px', marginTop:'4rem'}}>
            <HomePageContent />
          </Col>
        </Row> */}
      </Container>
  );
}

export default Home;
