import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Paper,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  ListItemText,
  Select,
  IconButton,
} from '@mui/material';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import axios from 'axios';
import { RemoveCircleOutline } from '@mui/icons-material';
import { format } from 'date-fns';
import BackButton from './BackButton';
import UnversalSpinner from './CommonUtilites/UniversalSpinner';

const weightOptions = ['100g', '250g', '500g', '1kg', '2kg', '5kg', '10kg', '25kg', '100ml', '250ml', '500ml', '1L', '2L', '5L', '10L', '25L'];

function AddItemForm() {

  const [loading, setLoading] = useState(false);
  const [weights, setWeights] = useState([
    {
      weight: '',
      mrp: '',
      costPrice: '',
      sellingPrice: '',
      discountPercent: '',
      gstPercent: '',
      calculatedGST: '',
      skr: '',
      availableStock: '',
      minPurchaseQty: '',
      dimensions: '',
      availabilityStatus: '',
    },
  ]);

  const [item, setItem] = useState({
    name: '',
    barCode: '',
    brand: '',
    category: '',
    description: '',
    warehouseLocation: '',
    image: null,
    additionalImages: [],
    purchaseDate: format(new Date(), "yyyy-MM-dd"),
    expiryDate: format(new Date(), "yyyy-MM-dd"),
  });

  const [errors, setErrors] = useState({});
  const [response, setResponse] = useState('');
  const [imagePreview, setImagePreview] = useState(null);
  const [additionalImagePreviews, setAdditionalImagePreviews] = useState([]);
  const [imgError, setImgError] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const formatFloat = (value) => { return parseFloat(value).toFixed(2); };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setItem({ ...item, [name]: value });
    console.log("handleInputChange", name, value)
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setItem({ ...item, image: file });
    setImagePreview(URL.createObjectURL(file));
    console.log("handleFileChange", e.target.files[0])
    setImgError(true);
  };

  const handleAdditionalFilesChange = (e) => {
    const files = Array.from(e.target.files);
    setItem({ ...item, additionalImages: files });
    setAdditionalImagePreviews(files.map((file) => URL.createObjectURL(file)));
    console.log("handleAdditionalFilesChange", e.target.files)
    setImgError(true);
  };

  const handleWeightChange = (index, field, value) => {
    const updatedWeights = [...weights];
    updatedWeights[index][field] = value;

    if (field === 'discountPercent') {
      const mrp = updatedWeights[index].mrp;
      const discountPercent = value;
      const sellingPrice = mrp - (mrp * discountPercent * 0.01);
      updatedWeights[index].sellingPrice = formatFloat(sellingPrice);
    }

    if (field === 'gstPercent') {
      const gstPercent = value; 
      const sellingPrice = updatedWeights[index].sellingPrice || updatedWeights[index].mrp; 
      const calculatedGST = sellingPrice * gstPercent * 0.01;
      updatedWeights[index].calculatedGST = formatFloat(calculatedGST);
    }

    setWeights(updatedWeights);
    console.log("handleWeightChange", updatedWeights);
  };


  const addWeightField = () => {
    setWeights([
      ...weights,
      {
        weight: '',
        mrp: '',
        costPrice: '',
        sellingPrice: '',
        discountPercent: '',
        gstPercent: '',
        calculatedGST: '',
        skr: '',
        availableStock: '',
        minPurchaseQty: '',
        dimensions: '',
        availabilityStatus: '',
      },
    ]);
  };

  const removeWeightField = (index) => {
    setWeights(weights.filter((_, i) => i !== index));
  };

  const handleDateChange = (name, date) => {
    const formattedDate = format(date, "yyyy-MM-dd");
    setItem({ ...item, [name]: formattedDate });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    // Append fields from the `item` object
    Object.keys(item).forEach((key) => {
      if (item[key] instanceof File) {
        // Append the file (e.g., `image`)
        formData.append(key, item[key]);
      } else if (Array.isArray(item[key])) {
        // Append additional images
        item[key].forEach((file) => {
          if (file instanceof File) {
            formData.append(key, file);
          } else {
            formData.append(key, JSON.stringify(file));
          }
        });
      } else {
        // Append other fields as string values
        formData.append(key, item[key]);
      }
    });

    // Append `weights` (product variants) as a JSON string
    formData.append('productVarients', JSON.stringify(weights));

    try {
      setLoading(true);
      const response = await axios.post('http://13.233.178.111:8080/addNewProduct', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setResponse(response.data);
      setLoading(false);
      setOpenModal(true);
      setItem({
        name: '',
        barCode: '',
        brand: '',
        category: '',
        description: '',
        warehouseLocation: '',
        image: null,
        additionalImages: [],
        purchaseDate: format(new Date(), "yyyy-MM-dd"),
        expiryDate: format(new Date(), "yyyy-MM-dd"),
      });
      setWeights([
        {
          weight: '',
          mrp: '',
          costPrice: '',
          sellingPrice: '',
          discountPercent: '',
          gstPercent: '',
          calculatedGST: '',
          skr: '',
          availableStock: '',
          minPurchaseQty: '',
          dimensions: '',
          availabilityStatus: '',
        },
      ]);
      setImagePreview(null);
      setAdditionalImagePreviews([]);
    } catch (error) {
      console.error('Error adding item:', error);
      setResponse('Error adding item. Please try again.');
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };


  useEffect(()=>{

  }, [item])

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" padding={3}>
      <Paper elevation={3} style={{ padding: '30px', width: '90%' }}>
        <Typography variant="h5" align="center" gutterBottom>
          Add New Item
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} margin={1} width={'95%'}>
            <Grid item xs={12} sm={6}>
              <TextField
                name="name"
                label="Item Name"
                fullWidth
                required
                value={item.name}
                onChange={handleInputChange}
                error={!!errors.name}
                helperText={errors.name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="barCode"
                label="Barcode"
                fullWidth
                required
                value={item.barCode}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="brand"
                label="Brand"
                fullWidth
                required
                value={item.brand}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="category"
                label="Category"
                fullWidth
                required
                value={item.category}
                onChange={handleInputChange}
              />
            </Grid>

            {weights.map((weight, index) => (
              <Grid container spacing={2} margin={1} padding={1} key={index} style={{ border: '2px solid lightgray', borderRadius: '10px' }}>
                <Grid item xs={12} sm={2}>
                  <FormControl fullWidth required>
                    <InputLabel>Weight</InputLabel>
                    <Select
                      value={weight.weight}
                      onChange={(e) => handleWeightChange(index, 'weight', e.target.value)}
                    >
                      {weightOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          <ListItemText primary={option} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    label="Cost Price"
                    type="number"
                    fullWidth
                    required
                    value={weight.costPrice}
                    onChange={(e) => handleWeightChange(index, 'costPrice', formatFloat(e.target.value))}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    label="MRP"
                    type="number"
                    fullWidth
                    required
                    value={weight.mrp}
                    onChange={(e) => handleWeightChange(index, 'mrp', formatFloat(e.target.value))}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    label="Discount %"
                    type="number"
                    fullWidth
                    required
                    value={weight.discountPercent}
                    onChange={(e) => handleWeightChange(index, 'discountPercent', formatFloat(e.target.value))}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    label="Selling Price"
                    type="number"
                    fullWidth
                    disabled
                    value={weight.sellingPrice}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    label="GST %"
                    type="number"
                    fullWidth
                    required
                    value={weight.gstPercent}
                    onChange={(e) => handleWeightChange(index, 'gstPercent', formatFloat(e.target.value))}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    label="GST Value"
                    type="number"
                    fullWidth
                    disabled
                    value={weight.calculatedGST}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    label="Pair Of"
                    type="number"
                    fullWidth
                    required
                    value={weight.minPurchaseQty}
                    onChange={(e) => handleWeightChange(index, 'minPurchaseQty', e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <TextField
                    label="Available Stock"
                    type="number"
                    fullWidth
                    required
                    value={weight.availableStock}
                    onChange={(e) => handleWeightChange(index, 'availableStock', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    label="Dimensions"
                    fullWidth
                    value={weight.dimensions}
                    onChange={(e) => handleWeightChange(index, 'dimensions', e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <FormControl fullWidth required>
                    <InputLabel id="availability-status-label">Availability Status</InputLabel>
                    <Select
                      value={weight.availabilityStatus}
                      onChange={(e) => handleWeightChange(index, 'availabilityStatus', e.target.value)}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>


                <Grid item xs={12} sm={2}>
                  <IconButton onClick={() => removeWeightField(index)} color="error">
                    <RemoveCircleOutline />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
            <Grid item xs={12} sm={3}>
              <Button onClick={addWeightField} variant="outlined" fullWidth>
                Add More Varients
              </Button>
            </Grid>
          </Grid>

          <Grid container spacing={2}>

            <Grid item xs={12} sm={6}>
              <TextField
                name="numberOfReviews"
                label="Number of Reviews"
                type="number"
                fullWidth
                value={item.numberOfReviews}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="manufacturer"
                label="Manufacturer"
                fullWidth
                value={item.manufacturer}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="modelNumber"
                label="Model Number"
                fullWidth
                value={item.modelNumber}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="warrantyInfo"
                label="Warranty Info"
                fullWidth
                value={item.warrantyInfo}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="returnPolicy"
                label="Return Policy"
                fullWidth
                value={item.returnPolicy}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="tags"
                label="Tags"
                fullWidth
                value={item.tags}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Purchase Date"
                  value={item.purchaseDate}
                  required
                  onChange={(date) => handleDateChange('purchaseDate', date)}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Expiry Date"
                  value={item.expiryDate}
                  required
                  onChange={(date) => handleDateChange('expiryDate', date)}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="description"
                label="Description"
                multiline
                rows={4}
                fullWidth
                value={item.description}
                onChange={handleInputChange}
              />
            </Grid>


            <Grid item xs={12} sm={6}>
              <Button variant="contained" component="label" fullWidth style={{ backgroundColor: imgError && item.image ? '' : 'red' , margin: '1rem 0rem'}}>
                Upload Image
                <input type="file" hidden name="image" onChange={handleFileChange} required />
              </Button>
            </Grid>
            {imagePreview && (
              <Grid item xs={12}>
                <img
                  src={imagePreview}
                  alt="Preview"
                  style={{ width: '250px', maxHeight: '250px', objectFit: 'cover', borderRadius: '8px' }}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <Button variant="contained" component="label" fullWidth style={{ backgroundColor: imgError && item.additionalImages.length > 0 ? '' : 'red' , margin: '1rem 0rem'}}>
                Upload Additional Images
                <input type="file" hidden multiple name="additionalImages" required onChange={handleAdditionalFilesChange} />
              </Button>
            </Grid>
            {additionalImagePreviews.length > 0 && (
              <Grid item xs={12}>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {additionalImagePreviews.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      alt="Additional Preview"
                      style={{ width: '100px', height: '100px', objectFit: 'cover', margin: '5px' }}
                    />
                  ))}
                </div>
              </Grid>
            )}

          </Grid>
            { loading && <UnversalSpinner />}
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Submit
          </Button>
        </form>

        {/* Modal to display response */}
        <Dialog open={openModal} onClose={handleCloseModal}>
          <DialogContent>
            <Typography variant="h6" align="center" color="textSecondary">
              {response}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <BackButton />

      </Paper>
    </Box>
  );
}

export default AddItemForm;
